import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import CrunchyRolls from "../../components/en/CrunchyRolls"
import Footer from "../../components/en/Footer"

class CreamRollsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Crunchy Rolls</title>
          <meta
            name="description"
            content="Nova dimenzija ukusa smeštena je unutar Crunchy Rolls, rolnica koje šire pozitivnu energiju, okupljaju stare i povezuju nove potrošače. "
          />
          <meta name="image" content="https://waltz.rs/crunchy_rolls_seo.jpg" />
          <meta itemprop="name" content="WALTZ | Crunchy Rolls" />
          <meta
            itemprop="description"
            content="Nova dimenzija ukusa smeštena je unutar Crunchy Rolls, rolnica koje šire pozitivnu energiju, okupljaju stare i povezuju nove potrošače. "
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/crunchy_rolls_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Crunchy Rolls" />
          <meta
            name="og:description"
            content="Nova dimenzija ukusa smeštena je unutar Crunchy Rolls, rolnica koje šire pozitivnu energiju, okupljaju stare i povezuju nove potrošače. "
          />
          <meta
            name="og:image"
            content="https://waltz.rs/crunchy_rolls_seo.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/crunchy-rolls" />
          <meta name="og:site_name" content="WALTZ | Crunchy Rolls" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <CrunchyRolls />
        <Footer />
      </Layout>
    )
  }
}
export default CreamRollsPage
